<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>AI Solutions, Human Expertise</h4>
            <h2><span>Plenipotentiary</span></h2>
            <p>
                Discover how AI is transforming operations, sustainability, and
                customer satisfaction.
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Operational Excellence Redefined</h3>
                    <p>
                        Discover the power of AI in revolutionizing day-to-day
                        operations across all industries. Our intelligent
                        solutions streamline processes, enhance productivity,
                        and reduce downtime through predictive maintenance and
                        smart automation. By harnessing the capabilities of AI,
                        businesses can achieve unparalleled operational
                        efficiency, ensuring a seamless flow from production to
                        delivery.
                    </p>
                    <a
                        (click)="onClick('about')"
                        title="Read More"
                        class="link-btn"
                        ><i class="fa fa-arrow-right"></i
                    ></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-lightbulb"></i>
                    </div>
                    <h3>Sustainability Meets Innovation</h3>
                    <p>
                        At the core of our mission lies the commitment to
                        sustainability. AI plays a critical role in enabling
                        eco-friendly practices within all industry sectors.
                        Through data-driven insights, AI optimizes resource use,
                        reduces waste, and minimizes environmental impact. Our
                        AI solutions empower businesses to not only meet their
                        sustainability goals but to set new benchmarks for
                        eco-conscious operations.
                    </p>
                    <a
                        (click)="onClick('about')"
                        title="Read More"
                        class="link-btn"
                        ><i class="fa fa-arrow-right"></i
                    ></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-user"></i>
                    </div>
                    <h3>Elevating Customer Experiences</h3>
                    <p>
                        In today’s competitive landscape, customer satisfaction
                        is paramount. AI opens new avenues for personalizing
                        customer interactions, predicting trends, and responding
                        proactively to customer needs. AI is at the forefront of
                        enriching customer experiences. By integrating AI,
                        businesses can foster stronger connections with their
                        customers, enhancing loyalty and driving growth.
                    </p>
                    <a
                        (click)="onClick('about')"
                        title="Read More"
                        class="link-btn"
                        ><i class="fa fa-arrow-right"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image" /></div>
</div>
