<footer class="footer-area">
    <div class="container">
        <h3>
            <a routerLink="/">Plenipotentiary <span>AI</span></a>
        </h3>
        <!-- <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul> -->
        <p>&copy; 2024 <span>P</span>lenipotentiary. All Rights Reserved</p>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
