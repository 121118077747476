<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>CONTACT PLENIPOTENTIARY AI</h4>
                <h2>
                    Unlock Your Business Potential with Tailor-Made AI Solutions
                </h2>
                <p>Empowering Innovation, Personalizing Technology</p>
                <a (click)="onClick('contact')" class="btn btn-primary"
                    >Contact Us</a
                >
                <a (click)="onClick('work')" class="btn btn-primary view-work"
                    >View Work</a
                >
            </div>
        </div>
    </div>
</div>
