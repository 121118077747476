<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2><span>Contact</span> Us</h2>
            <p>
                Connect With the Future: Reach Out to Plenipotentiary AI Today
            </p>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    <span>Location:</span> 30 Churchill Place,
                                    Canary Wharf, London, E14 5RE
                                </li>
                                <li>
                                    <i class="far fa-envelope"></i>
                                    <a
                                        href="mailto:info&#64;plenipotentiary.ai"
                                    >
                                        <span>Email:</span>
                                        info&#64;plenipotentiary.ai
                                    </a>
                                </li>
                                <li>
                                    <i class="fas fa-phone-alt"></i>
                                    <a href="tel:00442081238889"
                                        ><span>Call:</span>+44(0)2081238889</a
                                    >
                                </li>
                                <li>
                                    <i class="fas fa-mobile"></i>
                                    <a href="tel:00447747611991"
                                        ><span>Mobile:</span>+44(0)7747611991</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
