<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How We <span>Work</span></h2>
            <p>
                Embark on a transformative journey with Plenipotentiary AI in
                just three simple steps
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Discover and Define</h3>
                    <p>
                        Connect with Our Team: Begin your journey by reaching
                        out to us through our website, email, or phone. We'll
                        set up an initial consultation to discuss your business
                        needs, challenges, and aspirations.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Tailor-Made Strategy</h3>
                    <p>
                        Solution Design: Based on the insights gathered, we'll
                        design a tailor-made AI strategy that aligns with your
                        business objectives. This strategy will outline the
                        specific AI solutions we recommend, how they will be
                        implemented, and the expected outcomes.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Implementation and Growth</h3>
                    <p>
                        Ongoing Support & Optimization: Post-implementation,
                        we'll continue to support your business through
                        monitoring, maintenance, and continuous improvement of
                        the AI solutions. Our goal is to ensure you're achieving
                        the desired outcomes and that your AI investment
                        continues to drive value over time.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image" /></div>
</section>
