<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Services</span></h2>
            <p>
                At Plenipotentiary AI, we offer a comprehensive suite of AI
                solutions designed to empower your business across various
                domains. Discover how our tailored services can transform your
                operations, enhance your customer experience, and drive
                innovation:
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img
                            src="assets/home/service_1.jpg"
                            alt="services-img"
                        />
                        <div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>AI Strategy Consulting</h3>
                        <p>
                            Strategic AI Consulting: Leverage our expertise to
                            develop a comprehensive AI strategy that aligns with
                            your business goals.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img
                            src="assets/home/service_2.jpg"
                            alt="services-img"
                        />
                        <div class="icon">
                            <i class="fab fa-linode"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Custom AI Development</h3>
                        <p>
                            Bespoke AI Development: Tailor-made AI solutions
                            designed to meet your unique business challenges and
                            opportunities.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img
                            src="assets/home/service_3.jpg"
                            alt="services-img"
                        />
                        <div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>AI Integration Services</h3>
                        <p>
                            AI System Integration: Seamless integration of AI
                            technologies into your existing IT infrastructure to
                            enhance operational efficiency.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img
                            src="assets/home/service_4.jpg"
                            alt="services-img"
                        />
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>AI for Facility Management</h3>
                        <p>
                            Smart Building Solutions: Utilize AI to automate
                            building management systems, optimizing energy use
                            and improving sustainability.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img
                            src="assets/home/service_5.jpg"
                            alt="services-img"
                        />
                        <div class="icon">
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>AI in the Catering Industry</h3>
                        <p>
                            Supply Chain Optimization: AI-driven insights to
                            streamline supply chain operations, reducing waste
                            and enhancing efficiency.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img
                            src="assets/home/service_6.jpg"
                            alt="services-img"
                        />
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Data Analytics & Insights</h3>
                        <p>
                            Advanced Data Analytics: Transform data into
                            actionable insights with our advanced analytics
                            services, driving better decision-making.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
