import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { BlogDetailsComponent } from "./components/blog-details/blog-details.component";

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "blog-details", component: BlogDetailsComponent },
    { path: "**", component: HomeComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
