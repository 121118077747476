<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>About <span>Plenipotentiary</span></h2>
            <p>
                At Plenipotentiary AI, we stand at the vanguard of a
                transformative journey, envisioning a future where the symbiosis
                of artificial intelligence and human expertise redefines the
                frontiers of every industry.
            </p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Our <span>Genesis</span></h2>
                        <p>
                            Plenipotentiary AI was conceived with a clear
                            purpose: to pioneer a new paradigm where AI is not
                            merely a tool, but a collaborator, across the
                            spectrum of industry sectors. Starting with the
                            foundational realms of Facility Management and the
                            Catering Industry, we are laying down the groundwork
                            for a comprehensive AI integration strategy that
                            promises to revolutionize how businesses operate,
                            innovate, and interact with their customers.
                        </p>
                    </div>
                    <ul class="features-list">
                        <li>
                            <i class="fas fa-check"></i>
                            Identifying the Need for Integration
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            Vision of Collaboration
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            Foundation on Innovation
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            Strategic Focus Areas
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            Commitment to Ethical AI Use
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            Building a Multidisciplinary Team
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/home/our_genesis.jpg" alt="about" />
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
