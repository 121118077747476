<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Why We <span>Are Different</span></h2>
            <p>
                At Plenipotentiary AI, we're not just another AI technology
                company. Our approach, mission, and values set us apart in a
                crowded marketplace.
            </p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input
                type="radio"
                name="tabset"
                id="tab1"
                aria-controls="tab-1"
                checked
            />
            <label for="tab1">Ethical AI for a Sustainable Future</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="tab-2" />
            <label for="tab2">Collaboration Over Competition</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="tab-3" />
            <label for="tab3">Cutting-Edge Research and Development</label>
            <div class="tab-panels">
                <div id="tab-1" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Ethical AI for a Sustainable Future</h3>
                                <p>
                                    Our commitment to ethical AI practices and
                                    sustainability is at the core of everything
                                    we do. We develop AI solutions that not only
                                    drive business growth but also promote
                                    environmental stewardship and social
                                    responsibility. This dual focus ensures that
                                    our innovations contribute positively to the
                                    world.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/home/whyus_1.jpg" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tab-2" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Collaboration Over Competition</h3>
                                <p>
                                    We view our clients as partners in
                                    innovation. By working closely with them
                                    through every step of the AI integration
                                    process, we foster a collaborative
                                    environment that encourages open
                                    communication, mutual respect, and shared
                                    success.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/home/whyus_2.jpg" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tab-3" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Cutting-Edge Research and Development</h3>
                                <p>
                                    Our team is at the forefront of AI research,
                                    continuously exploring new technologies and
                                    methodologies. This relentless pursuit of
                                    innovation ensures that our solutions always
                                    represent the leading edge of what’s
                                    possible in AI.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/home/whyus_3.jpg" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
