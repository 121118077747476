<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>
                            “Partnering with Plenipotentiary AI has been a
                            game-changer for our hotel chain. Their innovative
                            AI solutions have revolutionized our facility
                            management, from predictive maintenance to energy
                            efficiency. The smart climate control system they
                            implemented not only reduced our energy consumption
                            by 30% but also enhanced the comfort of our guests,
                            leading to a noticeable increase in guest
                            satisfaction scores. Plenipotentiary AI didn't just
                            provide us with technology; they offered a
                            comprehensive strategy that aligned perfectly with
                            our sustainability goals and operational needs.
                            Their team's expertise, support, and dedication have
                            made them an invaluable partner in our journey
                            towards a more efficient, sustainable future.”
                        </p>
                        <div class="info">
                            <h3>Raymond</h3>
                            <span>Director, Eden Move</span>
                            <img
                                src="assets/home/feedback_1.jpg"
                                class="shadow rounded-circle"
                                alt="image"
                            />
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>
                            “Working with Plenipotentiary AI has transformed the
                            way we approach service in the catering industry.
                            Their tailored AI solutions have streamlined our
                            supply chain, drastically reduced waste, and
                            personalized our customer experiences in ways we
                            never thought possible. The predictive analytics
                            tool for demand forecasting has been particularly
                            impactful, ensuring we're always prepared, reducing
                            costs, and significantly improving our bottom line.
                            Moreover, the personalized menu recommendations have
                            taken our customer service to new heights, resulting
                            in a 15% boost in customer loyalty. Plenipotentiary
                            AI's approach is not just about implementing
                            technology; it's about enhancing every aspect of our
                            operation and customer interaction. They are truly
                            at the forefront of integrating AI in catering, and
                            we're excited to see where this partnership takes us
                            next.”
                        </p>
                        <div class="info">
                            <h3>Richard</h3>
                            <span>CEO, Meal To</span>
                            <img
                                src="assets/home/feedback_2.jpg"
                                class="shadow rounded-circle"
                                alt="image"
                            />
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
