<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-6 col-sm-6">
                <div class="funfact">
                    <i class="far fa-smile"></i>
                    <h3><span [countUp]="2">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-6 col-sm-6">
                <div class="funfact">
                    <i class="fas fa-shield-alt"></i>
                    <h3><span [countUp]="2">00</span></h3>
                    <p>Completed Projects</p>
                </div>
            </div>
        </div>
    </div>
</section>
