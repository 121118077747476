<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Who We <span>Are</span></h2>
            <p>
                At Plenipotentiary AI, we are more than a company; we are a
                collective of visionaries dedicated to reshaping the future of
                industries through artificial intelligence. Here's what defines
                us:
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>We are professional</h3>
                    <p>
                        Our team consists of industry veterans, AI researchers,
                        and business strategists, all united by a commitment to
                        excellence. With decades of combined experience, we
                        bring a level of professionalism that ensures our
                        solutions are not only innovative but also reliable and
                        effective.
                    </p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>We are passionate</h3>
                    <p>
                        Passion is the fuel that drives us forward. We are
                        deeply committed to the mission of integrating AI with
                        human expertise to unlock new possibilities. Our passion
                        for technology and its potential to transform lives and
                        industries is what motivates us to push the boundaries
                        of what's possible.
                    </p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>We are creative</h3>
                    <p>
                        Creativity is at the heart of innovation. We approach
                        challenges with a creative mindset, seeking
                        out-of-the-box solutions that defy conventional wisdom.
                        Our creative spirit enables us to envision and realize
                        AI applications that are as imaginative as they are
                        impactful.
                    </p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>We are solution</h3>
                    <p>
                        At the core of our identity is our role as solution
                        providers. We tackle complex problems with smart,
                        AI-driven solutions that enhance efficiency,
                        sustainability, and customer satisfaction. Our focus is
                        on delivering tangible results that drive growth and
                        innovation for our clients.
                    </p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>We are unique</h3>
                    <p>
                        Plenipotentiary AI stands out in the landscape of AI
                        integration. Our unique approach to blending AI with
                        human insights across various industries sets us apart.
                        We don't just follow trends; we aim to set them,
                        creating bespoke solutions that are tailored to the
                        specific needs of our clients.
                    </p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>We are support</h3>
                    <p>
                        We support our clients every step of the
                        way. From initial consultations to the implementation of
                        AI solutions and beyond, we're there to offer our
                        expertise, guidance, and support. Our commitment to our
                        clients' success is unwavering, ensuring they feel
                        confident and supported in their AI journey.
                    </p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
