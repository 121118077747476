<div class="main-banner">
    <video autoplay muted loop class="video-background">
        <source src="assets/video/home.mp4" type="video/mp4" />
    </video>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>PLENIPOTENTIARY</h4>
                    <h1>
                        Revolutionizing Industry Standards with <span>AI</span>
                    </h1>
                    <p>
                        Elevate efficiency and innovation in your operations
                        with our tailored AI solutions.
                    </p>
                    <a (click)="onClick('about')" class="btn btn-primary">
                        Get Started
                    </a>
                    <a
                        (click)="onClick('work')"
                        class="btn btn-primary view-work"
                    >
                        View Work
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<!-- <app-skill></app-skill> -->

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-funfacts></app-funfacts>

<!-- <app-team></app-team> -->

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<!-- <app-pricing></app-pricing> -->

<!-- <app-faq></app-faq> -->

<app-feedback></app-feedback>

<!-- <app-blog></app-blog> -->

<app-partner></app-partner>

<!-- <app-subscribe></app-subscribe> -->

<app-contact></app-contact>
