<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="/assets/home/our_strategy.jpg" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Scalable Solutions for a Dynamic Future</h4>
                        <h2>About <span>Strategy</span></h2>
                        <p>
                            At Plenipotentiary AI, our strategy is guided by a
                            singular vision: to seamlessly integrate artificial
                            intelligence with human expertise across all
                            industry sectors, beginning with the foundational
                            blocks of Facility Management and the Catering
                            Industry. Our approach is both ambitious and
                            methodical, designed to foster innovation,
                            efficiency, and sustainability at every level of
                            operation.
                        </p>
                    </div>
                    <ul class="features-list">
                        <li>
                            <i class="fa fa-check"></i>
                            Innovation Through Integration
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Collaboration Is Key
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Sustainability and Ethics at the Core
                        </li>
                        <li>
                            <i class="fa fa-check"></i>
                            Educate, Empower, Transform
                        </li>
                    </ul>
                    <p>
                        At Plenipotentiary AI, our strategy is more than a
                        plan—it's a promise to drive the future of industries
                        with AI technologies that are innovative, ethical, and
                        transformative. Join us as we shape a future where
                        technology and humanity converge to create unprecedented
                        value and opportunities.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image" /></div>
</div>
