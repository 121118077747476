<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Work</span></h2>
            <p>
                At Plenipotentiary AI, we pride ourselves on our innovative
                applications of artificial intelligence across various sectors,
                with a special focus on Facility Management and the Catering
                Industry. Our tailored AI solutions have transformed operations,
                enhanced efficiency, and elevated customer experiences.
            </p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ul class="nav-tabset">
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab1' }"
                >
                    <span (click)="switchTab($event, 'tab1')">
                        Facility Management
                    </span>
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab2' }"
                >
                    <span (click)="switchTab($event, 'tab2')"> Catering </span>
                </li>
            </ul>
            <div class="container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Transforming Facility Management</h3>
                                <p>
                                    Predictive Maintenance: Implementing AI
                                    algorithms that predict equipment failures
                                    before they happen, minimizing downtime and
                                    extending the life of essential machinery.
                                    Our work has revolutionized how facilities
                                    approach maintenance, shifting from reactive
                                    to proactive strategies.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Revolutionizing the Catering Industry</h3>
                                <p>
                                    Supply Chain Optimization: Our AI-driven
                                    tools analyze vast amounts of data to
                                    streamline inventory management, predict
                                    demand, and reduce waste. We've helped
                                    clients achieve unparalleled efficiency,
                                    reducing costs and environmental impact.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
